import React from 'react';
import './style.css';

const Main = () => (
    <div className="main">
      <h3>This website is a working in progress</h3>
      <>L BERNARDES DESENVOLVIMENTO DE SOFTWARE LTDA</>
      <div><span>D-U-N-S</span></div>
      <>
        823504608
      </>
    </div>
)

export default Main;
