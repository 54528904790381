import logo from './logo.svg';
import Main from './Main';
// import Privacy from './Privacy';
// import Terms from './Terms';
import Terms from './Chatty/Terms';
import Privacy from './Chatty/Privacy';
import './App.css';

function App() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const page = params.get('page');
  console.log(page)
  return (
    <div className="App">
      {!page && (
        Main()
      )}
      {page === "terms" && (
        Terms()
      )}
      {page === "privacy" && (
        Privacy()
      )}
    </div>
  );
}

export default App;
