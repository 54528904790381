import React from 'react';

const Privacy = () => (
    <div>
        <strong>Introduction</strong>
        <p>
            This is the Privacy Policy of apps of L BERNARDES DESENVOLVIMENTO DE SOFTWARE LTDA and its various & next versions (hereinafter collectively referred to as "us", "we", or "our") 
            and is incorporated into and is subject to our Terms of Use which can be found at https://lbernardes.dev.br/?page=terms. In this Privacy Policy, we refer 
            to our products and services as the "Service". Please read on to learn more about our data handling practices. Your use of the Service signifies that you agree with the terms of 
            this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use the Service.
        </p>
        <strong>Information we collect</strong>

        <p>When you install the Service on your device and register, personally identifiable information about you may be collected during the download process for the Service (as requested during the download process) and when you register with us. For registration, you are required to provide your your email address, city location, gender, name, depending on the device(s) you are using on the Service. You may also provide personally identifiable information, but that is optional. When you log in using your Facebook account, we may collect additional information you make available to us (see "Your User Profile" below).
        </p>
        <p>In addition, when you install the Service on your device and register, you may will be asked to allow us access to your address book. If you consent, we will have access to contact information in your address book on the device you use for the Service (names, numbers, emails, and Facebook IDs, but not notes or other personal information in your address book) and we will store them on our servers and use them to help you use the Service, for example, by synchronizing your contacts between different devices you may want to use with the Service.
        </p>
        <p>We may also collect and gather non-personally identifiable information, such as certain profile information including country of residence and preferences. In addition, we may collect and store information about your and others’ usage of and interaction with the Service and our websites, including matches, numbers of matches made by members, match durations, text messages, usage by geographies, device and connection information, IP address, device capability, bandwidth, statistics on page views, network type and traffic to and from our websites.
        </p>
        <p>Our applications allows you to share text messages, photos, screenshots, videos and other communications in the application with other users, and if you choose to do so your text messages, photos, screenshots, videos and other communications will be stored on our servers. In addition, if you choose to share these items with other users, we may not be able to remove them from our servers or make them unavailable to anyone you have shared them with. Sending and sharing text messages, photos, screenshots, videos or other communications in the application is your decision. By choosing to share that information, you should understand that you may no longer be able to control how that information is used and that it may become publicly available (depending in part on your actions or the actions of others with whom you have shared the information). We are not responsible for any use or misuse of information you share. . In addition, in case your conversation partner reports your abusive behavior or language to us, then the conversation information such as voices, screenshots or contents of conversation which has been stored only in your partner's device may be transferred to our servers. Such transferred information will be processed by us to ascertain the genuineness of the report and determine your penalty levels, if needed.
        </p>
        <p>We may also collect and gather user contents (e.g., photos, screenshots, comments, and other materials) that you create on the Service. Your photo can be taken by other users on our services, and If they use capturing function provided by us, those photos can be stored and used for our services and 3rd party’s service (e.g. Facebook, etc.). If you do not agree with all the terms of this Privacy Policy, please do not use the Service.To complete a commerce transaction on shop, you will be requested to provide your payment information, including your credit card number, card expiration date, CVV code, and billing address. In that event, we will transmit your information securely directly to a third party vendor or merchant who will collect such information in order to process and fulfill your purchase. We do not process or store your payment information. However, we may store other information about your purchases made on the Service, which may include the merchant’s name, the date, time and amount of the transaction and other behavioral information.
        </p>
        <p>We also collect error-reporting information if the Service crashes or hangs up so that we can investigate the error and improve the stability of the Service for future releases. In general these reports do not contain personally identifiable information, or only incidentally. As part of these error reports, we receive information about the type and version of your device, the device identifier, the time the error occurred, the feature being used and the state of the application when the error occurred. We do not use this information for any purpose other than investigating and fixing the error.
        </p>

        <strong>Sharing of your information</strong>

        <p>We will not rent or sell your information to third parties without your consent, except as noted in this Privacy Policy.</p>

        <p>Parties with whom we may share your information:</p>

        <p>- We may share User Content and your information (including but not limited to, information from cookies, log files, device identifiers, location data, and usage data) with businesses that are legally part of the same group of companies that is part of, or that become part of that group ("Affiliates"). Affiliates may use this information to help provide, understand, and improve the Service (including by providing analytics) and Affiliates' own services (including by providing you with better and more relevant experiences). But these Affiliates will honor the choices you make about who can see your contents.
        </p>
        <p>- We also may share your information as well as information from tools like cookies, log files, and device identifiers and location data, with third-party organizations that help us provide the Service to you ("Service Providers"). Our Service Providers will be given access to your information as is reasonably necessary to provide the Service under reasonable confidentiality terms.
        </p>
        <p>- We may also share aggregate or anonymous information with third parties, including advertisers and investors. For example, we may tell our advertisers the number of users our app receives. This information does not contain any personal or personally identifiable information, and is used to develop content and services that we hope you will find of interest.
        </p>
        <p>- We may remove parts of data that can identify you and share anonymized data with other parties. We may also combine your information with other information in a way that it is no longer associated with you and share that aggregated information.
        </p>
        <p>Parties with whom you may choose to share your User Content:
        </p>
        <p>- Any information or content that you voluntarily disclose for posting to the Service, such as User Content, becomes available to the public. With this feature, we can be protected from exhibitionism. Once you have shared User Content or made it public, that User Content may be re-shared by others.
        </p>
        <p>- If you remove information and contents (e.g. Screenshots, etc.) that you created on the Service, copies may remain viewable in cached and archived pages of the Service, or if other Users or third parties have copied or saved that information (e.g. Facebook, etc.).
        </p>

        <strong>How we use information we collect</strong>

        <p>We use or may disclose your personal information only as follows:</p>
        <ul>
            <li>To verify your identity;</li>
            <li>To connect you with others as enabled by the Service;</li>
            <li>To share your Profile with others on the Service;</li>
            <li>To show you the names of persons you communicate with and to show your name to persons you communicate with on the Service;</li>
            <li>To deliver to you any administrative notices, alerts and communications relevant to your use of the Service;</li>
            <li>To provide you with relevant content that you requested, using information that you allow us to collect from you or that you provide to a social media provider with which your account is connected, such as information regarding your and your contacts' respective locations;</li>
            <li>To contact you via email, SMS or otherwise for the purpose of informing you about new products, services or promotions offered by us (you can opt-out of such emails or SMS by sending an email to contact@lbernardes.dev.br);</li>
            <li>For internal operations, including troubleshooting problems, data analysis, testing, research, improvements to the Service, detecting and protecting against error, fraud or other illegal activity;</li>
            <li>When we have a good faith belief that the law, any legal process, law enforcement, national security or issue of public importance requires disclosure;</li>
            <li>To protect and defend our rights or property (including to enforce our Terms of Use and other agreements); or</li>
            <li>In connection with a corporate transaction involving us, such as the purchase or sale of a business unit, an acquisition, merger, sale of assets, or other similar event.</li>
            <li>To allow your use of certain features of the Service that may be offered from time to time (such as Premium Services (see our Terms of Use));</li>
        </ul>

        <strong>Your User Profile</strong>
        <p>The information you enter into your user profile (your “Profile”) may be shared with your contacts. You control your Profile and you can access and modify your Profile from the application at any time. In addition, if you register using your Facebook account (e.g. Facebook Connect), we may store your Facebook ID and corresponding token on our servers and, if you give us your express consent, we will have access to and may collect certain information that you make available on Facebook (for example, your first and last names, email address, profile picture, your friend lists and birthdays). Your Profile is available to other users of the Service who are connected to you on our applications. In addition, unless you opt-out, your Profile is discoverable by other users, including by way of example in listings of contacts that will include your proximity to other users. You may also “block out” any user in your contact list from discovering your Profile at any time. In addition, we may pre-populate your Profile with certain information such as your name and Facebook profile picture if you or your friends have logged onto the Service using Facebook.
        </p>

        <strong>Data Access and Removal</strong>
        <p>You can always control what information you choose to share with us on the Service. To do so, you can change your settings in the Service or in your mobile device. Alternatively, you can remove the Service from your mobile device entirely.You can remove your data anytime you want. If you ask us to delete your account, we will use commercially reasonable efforts to remove your data from our servers.Any personally identifiable information that (i) you share in text messages, photos, videos or otherwise in or through the application with other users, or (ii) submit on a blog, bulletin board or chat room on our website or elsewhere, can be viewed and used by others, including to send you unsolicited messages or to commit identity theft. We are not responsible for any use or misuse of your information that might result from your disclosure of information.
        </p>

        <strong>Service Providers</strong>
        <p>In certain instances, we may have contracts with third parties to provide products and/or services in connection with the Service. In those cases, we may share your personal information with our third-party service providers, but only in connection with providing the services to you. For example, we may share your phone number or email with third party SMS and email providers to deliver the authentication SMS or email to you when you register. We contractually require that these third parties use your information solely to deliver SMS or email to you and to use appropriate security measures to protect your information. In addition, certain servers are owned and hosted by third party service providers. This Privacy Policy does not apply to the practices of third party service providers.
        </p>

        <strong>Outbound Links</strong>
        <p>If you accessed a website, product or service provided by a third party, including through the Service, such third party may also collect information about you. Please see the privacy policies of each such third party for more information about how they use the information they collect. This Privacy Policy does not apply to any exchange of information between you and any third party.
        </p>

        <strong>Opt-Out Promotional Communications</strong>
        <p>We allow you to choose not to receive promotional email messages and its service providers. You may opt-out by following instructions in the message sent by us or its service providers on how to unsubscribe from that particular mailing. You may also opt-out by contacting us at contact@lbernardes.dev.br.
        </p>

        <strong>Security</strong>
        <p>Protecting user privacy and personal information is a top priority for us. We make substantial efforts to ensure the privacy of all personally identifiable information you provide to us. Access to all personally identifiable information is restricted to those employees, contractors, agents and third-party service providers who need to know that information in order to provide, operate, develop, maintain, support or improve the Service. we use password protection, access logs, and system monitoring to safeguard the confidentiality and security of all member information.
        </p>
        <p>In addition, due to the inherent nature of the Internet and related technology, we do not guarantee the protection of information under our control against loss, misuse or alteration.
        </p>

        <strong>Age</strong>
        <p>The Service is not directed to children under the age of thirteen (13) and we do not knowingly collect personally identifiable information from children under the age of thirteen as part of the Service. If we become aware that we have inadvertently received personally identifiable information from a user under the age of thirteen as part of the Service, we will delete such information from our records. If we change our practices in the future, we will obtain prior, verifiable parental consent before collecting any personally identifiable information from children under the age of thirteen as part of the Service.
        </p>

        <strong>Notification of Changes</strong>
        <p>We reserve the right at our discretion to make changes to this Privacy Policy. You may review updates to our Privacy Policy at any time via links on our website. You agree to accept electronic communications and/or postings of a revised Privacy Policy on our website, and you agree that such electronic communications or postings constitute notice to you of the Privacy Policy. We reserve the right to modify this policy from time to time, so please review it frequently.If we make material changes to this policy, we will notify you by publishing a revised Privacy Policy or by means of a notice on our website, or as required by law. You agree to review the Privacy Policy periodically so that you are aware of any modifications. You agree that your continued use of the Service after we publish a revised Privacy Policy or provide a notice on our website constitutes your acceptance of the revised Privacy Policy. If you do not agree with the terms of the Privacy Policy, you should not use the Service.
        </p>

        <strong>Your rights</strong>
        ‍<p>If you wish to use any of the rights described below, you may contact us at any time by emailing us at contact@lbernardes.dev.br. We process and answer your requests without undue delay and in any event within one month of our receipt of the request unless a longer period is required due to the complexity of the request. In this case, our response time can be up to three months in total as permitted by Article 12 of the GDPR.
        </p>


        ‍<p>1) Right to request access</p>

        <p>You have the right to request access into the data that we are processing on you, see Article 15 of the GDPR, including information about:</p>
        <ul>
            <li>the purposes of the processing</li>
            <li>the categories of personal data concerned</li>
            <li>the recipients or categories of recipient to whom the personal data have been or will be disclosed</li>
            <li>the envisaged period for which the personal data will be stored</li>
        </ul>
        ‍
        <p>Furthermore, you have the right to obtain a copy of the personal data undergoing processing. Please note that the access may be restricted due to intellectual property or trade secrets.</p>


        <p>2) The right to object</p>
        <p>You have the right to object to our processing of your personal data on grounds relating to your particular situation when the data are processed based on the balancing-of interest rule in Section 6(1)(f) of the GDPR, see Article 21 of the GDPR. In this case, we will cease the processing unless there is compelling legitimate grounds for the processing which override your interests, rights and freedoms or if the processing is necessary for the establishment, exercise or defense of legal claims. You have the right to object to our processing of your personal data for direct marketing purposes at any time. We will cease the processing of your personal data for this purpose after the objection. Please note that if you exercise this right, your user license to use the Service will cease automatically.
        </p>


        <p>3) Right to rectification and erasure</p>
        <p>You have the right to have inaccurate personal data rectified, see Article 16 of the GDPR. Furthermore, you have the right to have your personal data erased where one of the following grounds applies, see Article 17 of the GDPR:
        ‍</p>
        <ul>
            <li>the personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed,</li>
            <li>if you have withdrawn your consent and there are no other legal grounds for the processing,</li>
            <li>if you have objected to the processing and there are no overriding legitimate grounds for the processing,</li>
            <li>the personal data have to be erased for compliance with a legal obligation in Union or Member State law,</li>
            <li>the personal data have been unlawfully processed or</li>
            <li>the personal data have been collected in relation to the offer of information society services.</li>
        </ul>

        <p>Please note that your right to erasure may be limited if the data are necessary for compliance with a legal obligation or for the establishment, exercise or defense of legal claims.
        </p>

        <p>4) Right to withdraw consentYou have the right to obtain restriction of processing in certain circumstances, see Article 18 of the GDPR. If you have the right to restriction, we will only process your data with your consent or for the establishment, exercise or defense of a legal claim or to protect a person or important grounds of public interest the right to withdraw consent. If we have asked for your consent to our processing of your data, you have the right to withdraw your consent at any time, see Article 7 of the GDPR. If you withdraw your consent, we will cease processing of the data for which you have withdrawn consent, unless we have a legal obligation to keep some or parts of your data. Please note that if you withdraw your consent, your user license to use the Services will cease automatically. The withdrawal of your consent does not affect the lawfulness of processing based on your consent before its withdrawal.
        </p>

        <p>5) The right to data portabilityYou have the right to receive the personal data you have provided us with which we process in a structured, commonly used and machine-readable format and have the right to transmit those data to another controller if the processing is based on consent or contract performance, see Article 20 of the GDPR.
        </p>

        <strong>Contact Information</strong>

        <p>If you have any questions about this Privacy Policy, please contact us at contact@lbernardes.dev.br. Any personally identifiable information provided in connection with inquiries related to this Privacy Policy will be used solely for the purpose of responding to the inquiry and consistent with our Privacy Policy.
        </p>‍

        <strong>Date</strong>
        <p>This privacy policy was posted on December 1, 2022.</p>
    </div>
)

export default Privacy;